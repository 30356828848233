import tableGetters from "@/store/templates/table/getters";
import { cloneDeep, isArray } from "lodash";

export default {
  ...tableGetters,
  category: state => (categoryId, isCloned = false) =>
    isCloned ? cloneDeep(state.entity[categoryId]) : state.entity[categoryId],
  tree: (state, getters) => {
    const rootCategories = cloneDeep(
      Object.values(state.entity).filter(category => category.isRoot)
    );
    denormalizeCategoryChildren(rootCategories, getters);
    return rootCategories;
  },
  subjectsByCategoryId: state => categoryId => {
    const category = state.entity[categoryId];
    return category.categorySubjects.map(categorySubjectId => {
      const categorySubject = state.categorySubject[categorySubjectId];
      return state.subject[categorySubject.subject];
    });
  },
  categorySubject: state => id => state.categorySubject[id],
  subject: state => id => state.subject[id]
};

function denormalizeCategoryChildren(categories, getters) {
  categories.forEach(category => {
    if (isArray(category.children)) {
      category.children = category.children.map(categoryId =>
        getters.category(categoryId, true)
      );
      denormalizeCategoryChildren(category.children, getters);
    }
  });
}
